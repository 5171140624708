import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Button from '#Button'

import { faTriangleExclamation, faUserPen, faChevronLeft, faChevronRight, faCalendar } from '@fortawesome/pro-regular-svg-icons'
import EditDirector from './EditDirector'

const dows = [ 'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato' ]
const months = [ 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre' ]

const useTabDef = (directors) => {
	return useMemo(() => ([
		{ label:"Data", content:row => row?.date?.split('-')?.reverse()?.join('/') },
		{ label:"Giorno", content:row => dows[new Date(row.date).getDay()] },
		{ label:"Direttore", content:row => {
			const director = row.director && directors.find(director => director.value === row.director)?.label
			return director || <FontAwesomeIcon icon={faTriangleExclamation} color="red" />
		}},
	]), [ directors ])
}

const SelectMonth = () => {
	const { year, month } = useParams()
	const baseLink = '/bookings/65fe0659c8fc12a1a81876b4'
	const now = new Date()
	const curYear = now.getFullYear()
	const curMonth = now.getMonth() + 1

	const todayLink = baseLink + '/' + curYear + '/' + curMonth
	const prevDate = new Date(year, parseInt(month) - 1, 1).toISOString().slice(0, 7).split('-').join('/')
	const prevLink = baseLink + '/' + prevDate
	const nextDate = new Date(year, parseInt(month) + 1, 1).toISOString().slice(0, 7).split('-').join('/')
	const nextLink = baseLink + '/' + nextDate
	return (
		<Table>
			<TableRow>
				<TableCell colSpan={dows.length}>
					<Box sx={{ display:'flex', justifyContent:'space-between' }}>
						<Box sx={{ mt:'auto', mb:'auto' }}>
							<Button icon={faChevronLeft} linkTo={prevLink} />
						</Box>
						<Box>
							<Typography variant='h4'>
								<Button icon={faCalendar} linkTo={todayLink} />
								{months[month-1] + ' ' + year}
							</Typography>
						</Box>
						<Box sx={{ mt:'auto', mb:'auto' }}>
							<Button icon={faChevronRight} linkTo={nextLink} />
						</Box>
					</Box>
				</TableCell>
			</TableRow>
		</Table>
	)
}

export default function TrackList() {
	const { shootingRange, year, month } = useParams()
	const api = useContext(APICtx)
	const [ dates, setDates ] = useState([])
	const [ editDirector, setEditDirector ] = useState()
	const [ directors, setDirectors ] = useState([])
	const tabDef = useTabDef(directors)

	const handleRefresh = () => api.call('booking/dates', { shootingRange, year, month }).then(setDates)
	useEffect(() => {
		api.call('director/list/options').then(directors => {
			setDirectors(directors)
			handleRefresh()
		})
	}, [ year, month ])

	const getRow = async date => {
		const { _id:booking, ...data } = await api.call('booking/get', { shootingRange, date })
		return { ...data, booking }
	}

	const isAdmin = api.auth.chk('bookings-admin')

	const defBtns = []
	isAdmin && defBtns.push({
		icon: faUserPen,
		label: 'Imposta direttore',
		onClick: setEditDirector,
	})

	return (
		<Tpl title="Prenotazioni poligono" backTo="/bookings">
			<EditDirector handleRefresh={handleRefresh} handleClose={() => setEditDirector(null)} {...editDirector} />
			<SelectMonth />
			<DataList def={tabDef} ids={dates} getRow={getRow} rowActions={defBtns} />
		</Tpl>
	)
}
