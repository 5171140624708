import React, { useState, useEffect, useContext } from 'react'
import Form, { Input, Select } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

const EditForm = ({ _id, directors, shootingRange, date }) => {
	const formDef = {
		_id,
		callSet: "booking/set",
		callGet: _id && "booking/get",
		extraData: { shootingRange, date },
	}

	return (
		<Form {...formDef}>
			<Select label="Direttore" name="director" options={directors} emptyLabel="-- nessun direttore assegnato --" required />
		</Form>
	)
}

const EditDirector = ({ _id, booking, shootingRange, date, handleClose, handleRefresh, handleRowRefresh }) => {
	const api = useContext(APICtx)
	const [ directors, setDirectors ] = useState([])

	useEffect(() => {
		api.call('director/list/options').then(setDirectors)
	}, [])

	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh?.() : handleRefresh()
	}

	const gfxDate = _id?.split('-')?.reverse()?.join('/') || ''

	return (
		<Dialog
			handleClose={triggerClose}
			open={Boolean(_id) || _id === null}
			title={ (_id ? "Modifica" : "Creazione") + " direttore - " + gfxDate }
			width="md"
		>
			<EditForm _id={booking} shootingRange={shootingRange} date={date} directors={directors} />
		</Dialog>
	)
}
export default EditDirector
