import React, { useMemo } from 'react';
import { Routes, Route } from "react-router-dom";

import APICtx, { useApi } from '#api'
import Screen, { TplWSidebar as Tpl } from '#Template'

import BookingListPage from './booking/List'
import BookingMonthPage from './booking/MonthCal'
import DirectorListPage from './director/List'
import SRangeListPage from './shooting-range/List'
import { faCalendar, faBuildingFlag, faUserPolice } from '@fortawesome/pro-solid-svg-icons';

const Router = () => (
	<Routes>
		<Route path="/bookings/:shootingRange/:year/:month" element={<BookingMonthPage />} />
		<Route path="/bookings" element={<BookingListPage />} />

		<Route path="/directors" element={<DirectorListPage />} />
		<Route path="/shooting-ranges" element={<SRangeListPage />} />
		
		<Route path="/" element={<Blank />} />
	</Routes>
)

const Blank = () => (
	<Tpl title="Tiro a segno" />
)

const useMenu = (api) => useMemo(() => {
	const menu = []

	if(api.auth.chk('bookings-view'))
		menu.push([
			{ label:"Calendari poligoni", linkTo:"/bookings", icon:faCalendar },
		])

	const menuMgm = []
	if(api.auth.chk('shooting-ranges-admin'))
		menuMgm.push({ label:"Gestione poligoni", linkTo:"/shooting-ranges", icon:faBuildingFlag })
	if(api.auth.chk('directors-admin'))
		menuMgm.push({ label:"Direttori", linkTo:"/directors", icon:faUserPolice })
	if(menuMgm.length)
		menu.push(menuMgm)

	return menu
}, [ api ])

export default function App() {
	const api = useApi()
	const menu = useMenu(api)

	return (
		<APICtx.Provider value={api}>
			<Screen toolAuth menu={menu}>
				{api.chkAuth() ? <Router /> : <Blank />}
			</Screen>
		</APICtx.Provider>
	)
}
